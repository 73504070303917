import { createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fcb9031e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "canvasRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "sakura-falling",
    style: _normalizeStyle(`top: ${_ctx.homeHeight}px;}`)
  }, [_createElementVNode("canvas", _hoisted_1, null, 512)], 4)), [[_vShow, _ctx.isBlinkingCursorShow]]);
}